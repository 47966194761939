import API from "../../../../../global/api";
import jsPDF from "jspdf";
import moment from "moment";
import { sortBy, split, sumBy, find ,groupBy} from "lodash";
export const requestGetProductByArea = () => {
  return {
    type: "REQUEST_GET_PRODUCT_BY_AREA",
  };
};
export const successGetProductByArea = (data) => {
  return {
    type: "SUCCESS_GET_PRODUCT_BY_AREA",
    payload: data,
  };
};
export const errorGetProductByArea = () => {
  return {
    type: "ERROR_GET_PRODUCT_BY_AREA",
  };
};
export const getProductByArea = (id, data) => {
  return (dispatch) => {
    dispatch(requestGetProductByArea());
    API.post(`/booking/bookingProductWithArea/${id}`, data)
      .then((res) => {
        let data1 = res.data;
        dispatch(successGetProductByArea(data1));
      })
      .catch((error) => {
        dispatch(errorGetProductByArea());
      });
  };
};
export const requestAddIssuance = () => {
  return {
    type: "REQUEST_ADD_ISSUANCE",
  };
};
export const successAddIssuance = (data) => {
  return {
    type: "SUCCESS_ADD_ISSUANCE",
    payload: data,
  };
};
export const errorAddIssuance = () => {
  return {
    type: "ERROR_ADD_ISSUANCE",
  };
};
export const addIssuance = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddIssuance());
    API.post(`/issuance`, data)
      .then((res) => {
        dispatch(successAddIssuance(data));
        handleVisible(res.data.message);
      })
      .catch((error) => {
        dispatch(errorAddIssuance());
        handleDangerVisible(error.response.data.message);
      });
  };
};
export const requestGetIssuance = () => {
  return {
    type: "REQUEST_GET_ISSUANCE",
  };
};
export const successGetIssuance = (data) => {
  return {
    type: "SUCCESS_GET_ISSUANCE",
    payload: data,
  };
};
export const errorGetAllIssuance = () => {
  return {
    type: "ERROR_GET_ISSUANCE",
  };
};
export const getIssuance = (data) => {
  return (dispatch) => {
    dispatch(requestGetIssuance());
    API.get(`/issuance?limit=1000&page=0&search`)
      .then((res) => {
        let data1 = res.data;
        dispatch(successGetIssuance(data1));
      })
      .catch((error) => {
        dispatch(errorGetAllIssuance());
      });
  };
};

export const UpdateRequestIssuance = () => {
  return {
    type: "UPDATE_REQUEST_ISSUANCE",
  };
};
export const UpdateSuccessIssuance = (data) => {
  return {
    type: "UPDATE_SUCCESS_ISSUANCE",
    payload: data,
  };
};

export const UpdateErrorIssuance = () => {
  return {
    type: "UPDATE_ERROR_ISSUANCE",
  };
};

export const updateIssuance = (data, oldData, salesMenList, updateTableQuery) => {
  console.log(salesMenList, data.salesMenId.name, "uiiiiiiiiiiiiiiiiiiiiiiiiiii")
  return (dispatch) => {
    dispatch(UpdateRequestIssuance());
    API.put(
      `/issuance/${oldData._id}`,
      {
        salesMenId: data.salesMenId.name,
        date: data.date,
        // IssuanceName: data.IssuanceName,
        // IssuanceDesc: data.IssuanceDesc,
        // location: data.location,
        // ownerName: data.ownerName,
        // ownerNumber: data.ownerNumber,
        // ownerCnic: data.ownerCnic,
      },
      {}
    )
      .then((res) => {
        updateTableQuery()
        dispatch(UpdateSuccessIssuance([data]));
      })
      .catch((error) => dispatch(UpdateErrorIssuance()));
  };
};

export const DeleteRequestIssuance = () => {
  return {
    type: "DELETE_REQUEST_ISSUANCE",
  };
};
export const DeleteSuccessIssuance = (id) => {
  return {
    type: "DELETE_SUCCESS_ISSUANCE",
    id: id,
  };
};
export const DeleteErrorIssuance = () => {
  return {
    type: "DELETE_ERROR_ISSUANCE",
  };
};

export const deleteIssuance = (id, updateTableQuery) => {
  return (dispatch) => {
    dispatch(DeleteRequestIssuance());
    API.delete(`/issuance/${id}`)
      .then((res) => {
        dispatch(DeleteSuccessIssuance(id));
        updateTableQuery()
      })
      .catch((error) => dispatch(DeleteErrorIssuance()));
  };
};

export const requestGenerateIssuancePDF = () => {
  return {
    type: "REQUEST_GENERATE_ISSUANCE_PDF",
  };
};
export const successGenerateIssuancePDF = (data) => {
  return {
    type: "SUCCESS_GENERATE_ISSUANCE_PDF",
    payload: data,
  };
};
export const errorGenerateIssuancePDF = () => {
  return {
    type: "ERROR_GENERATE_ISSUANCE_PDF",
  };
};
function splitString(str) {
  var alpha = "";
  let num = "";
  let special = "";
  for (let i = 0; i < str.length; i++) {
    if (!isNaN(String(str[i]) * 1))
      num += str[i];
    else if ((str[i] >= 'A' && str[i] <= 'Z') ||
      (str[i] >= 'a' && str[i] <= 'z'))
      alpha += str[i];
    else
      special += str[i];
  }

}

export const generateIssuancePDF = (id) => {
  return (dispatch) => {
    // let name = data.name;
    dispatch(requestGenerateIssuancePDF());
    API.get(`/issuance/${id}`)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "Dataaaaaa");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 4;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(11);
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        doc.setFontSize(6);
        // doc.rect(15, 170, 550, 50);
        const headers = [
          ["Sr.No", "Code", "Product Label", "Rate", "Qty", "RTG", "RTG Amt", "Total"],
        ];
        // const check=(x,array)=>{
        //   let t=0
        //   let y=t+array.map((j,k)=>(x.product._id==j.product?j.quantity:0))
        //   console.log(t,y,"testttttttttt")
        //   // return 
        // }
        const SortedArray = sortBy(getData.data[0].data, [function (o) { return o.product.name; }])
        console.log(SortedArray, "jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj")
        let t = 0
        const data = SortedArray.map((x, i) => (
          // console.log(check(x,getData.data[0].returnProducts),"loooooooooooo"),
          [
            i + 1,
            x.product.code,
            x.product.name,
            x.productSellingPrice,
            x.quantity,
            // getData.data[0].returnProducts.map((j,k)=>x.product._id==j.product&&x.quantity+j.quantity),
            // Number(x.quantity)+Number(getData.data[0].returnProducts.map((j,k)=>(x.product._id==j.product?j.quantity:0))),
            // split(x.productLabel,'-'),
            "",
            // getData.data[0].returnProducts.map((j)=>j.product==x.product._id?j.quantity:0),
            "",
            x.productSellingPrice * x.quantity,
          ]));

        let content = {
          margin: {
            left: 15,
            right: 30,
            // size:2
          },
          startY: 120,
          styles: { fontSize: 8 },
          // setFontSize(6),
          head: headers,
          body: data,
          theme: "grid",
          bodyStyles: { lineColor: 'black' }
        };
        doc.setFontSize(20);
        doc.setFont("bold");
        doc.text(companyName, 220, 30, 0, 20);
        doc.setFontSize(16);
        doc.setFont("TimesNewRoman", "bold");
        doc.rect(15, 50, 550, 65);
        // number
        doc.text(
          `${getData.data[0].number} `,
          520,
          70,
          0,
          20
        );

        doc.setFontSize(11);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(
          `Date:${getData.data[0].date} `,
          320,
          70,
          0,
          20
        );
        doc.text(
          `Print On: ${moment().format('L')} ${moment().format('LTS')} `,
          320,
          90,
          0,
          20
        );
        doc.text(
          `Sales Man:${getData.data[0].salesMenId.name} `,
          30,
          70,
          0,
          20
        );
        doc.text(
          `Total Bills: ${getData.bookingTotal}`,
          320,
          110,
          0,
          20
        );
        doc.text(`Area: ${getData.data[0].areaId.areaName}`, 30, 90, 0, 20);
        doc.text(
          `Booker: ${getData.data[0].bookerDetails.userName}`,
          30,
          110,
          0,
          20
        );


        doc.setFontSize(6);
        // doc.rect(15, 160, 550, 100);
        doc.autoTable(content);
        console.log(doc.lastAutoTable.finalY, "yyyyyyyyyyy")
        // let finalY=doc.lastAutoTable.finalY
        // if(data.length>29&&doc.length<30){
        //   doc.addPage()
        //   finalY=5
        // }
        doc.setFontSize(10);
        doc.lastAutoTable.finalY > 780 && doc.addPage()
        let finalY = doc.lastAutoTable.finalY > 780 ? 5 : doc.lastAutoTable.finalY;
        // doc.rect(100, finalY + 10, 450, 80);
        doc.text(
          `Total: ${sumBy(data, "7")}`,
          450,
          finalY + 15
        );
        doc.text(
          `Discount: ${getData.data[0].bookingDiscount}`,
          450,
          finalY + 30
        );
        // doc.line(400, finalY + 70, 550, finalY + 70);
        doc.text(
          `Net Total: ${sumBy(data, "7") -
          getData.data[0].bookingDiscount
          }`,
          450,
          finalY + 45
        );

        // doc.text(`Return:${0}`, 420, finalY + 110);
        // doc.line(400, finalY + 120, 550, finalY + 120);
        // doc.text(`دوکان داراپنا مال  tedtڑیلوری کے وقت پورا کر لے۔ بغد میں کمپنی زمہ دار نہ ہو گی۔`, 10, finalY + 65);
        doc.setProperties(
          `Order Issuance Report ${moment(new Date()).format("YYYY-MM-DD")}`
        );
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));
        // doc.save(
        //   `Order Issuance Report ${moment(new Date()).format("YYYY-MM-DD")}`
        // );

        dispatch(successGenerateIssuancePDF(getData));
      })
      .catch((error) => {
        console.log(error, 'errrrrrrrrrrrrrrrr')
        dispatch(errorGenerateIssuancePDF());
      });
  };
};
export const getIssuanceById = (id) => {
  return (dispatch) => {
    // let name = data.name;
    dispatch(requestGenerateIssuancePDF());
    API.get(`/issuance/${id}`)
      .then((res) => {
        let getData = res.data;

        dispatch(successGenerateIssuancePDF(getData));
      })
      .catch((error) => {
        dispatch(errorGenerateIssuancePDF());
      });
  };
};

// Add Payment

export const requestAddPayment = () => {
  return {
    type: "REQUEST_ADD_PAYMENT",
  };
};
export const successAddPayment = (data) => {
  return {
    type: "SUCCESS_ADD_PAYMENT",
    payload: data,
  };
};
export const errorAddPayment = () => {
  return {
    type: "ERROR_ADD_PAYMENT",
  };
};
export const addPayment = (id, data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddPayment());
    API.post(`/issuance/receive_issuance_payment/${id}`, data)
      .then((res) => {
        let getData = res.data;
        dispatch(successAddPayment(getData));
        dispatch(getIssuanceByStatus());
        handleVisible(res.data.message);
      })
      .catch((error) => {
        console.log(error, "qqqqqqqqqqqq");
        dispatch(errorAddPayment());
        handleDangerVisible(error.response.data.message);
      });
  };
};

// Get Issuance By Status

export const requestGetIssuanceByStatus = () => {
  return {
    type: "REQUEST_GET_ISSUANCE_BY_STATUS",
  };
};
export const successGetIssuanceByStatus = (data) => {
  return {
    type: "SUCCESS_GET_ISSUANCE_BY_STATUS",
    payload: data,
  };
};
export const errorGetIssuanceByStatus = () => {
  return {
    type: "ERROR_GET_ISSUANCE_BY_STATUS",
  };
};
export const getIssuanceByStatus = () => {
  return (dispatch) => {
    dispatch(requestGetIssuanceByStatus());
    API.get(`/issuance/get_active_issuance`)
      .then((res) => {
        let data1 = res.data;
        dispatch(successGetIssuanceByStatus(data1));
      })
      .catch((error) => {
        dispatch(errorGetIssuanceByStatus());
      });
  };
};

export const generatePaymentPDF = (rowData) => {
  return (dispatch) => {
    console.log(rowData, "Dataaaaaa");
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 4;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(11);
    const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
    doc.setFontSize(6);
    // doc.rect(15, 170, 550, 50);
    const headers = [
      ["Sr.No", "Code", "Product Label", "Rate", "Qty", "Total"],
    ];
    const SortedArray = sortBy(rowData.finalData, [function (o) { return o.product.name; }])
    // console.log(SortedArray,"jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj")
    const data = SortedArray.map((x, i) => (
      [
        i + 1,
        x.product.code,
        x.product.name,
        x.productSellingPrice,
        x.quantity,
        x.productSellingPrice * x.quantity,
      ]));

    let content = {
      margin: {
        left: 15,
        right: 30,
        // size:2
      },
      startY: 155,
      styles: { fontSize: 9 },
      // setFontSize(6),
      head: headers,
      body: data,
      theme: "grid",
    };
    doc.setFontSize(20);
    doc.setFont("bold");
    doc.text(companyName, 220, 40, 0, 20);
    doc.setFontSize(12);
    doc.setFont("TimesNewRoman", "Normal");
    doc.rect(15, 60, 550, 85);
    doc.text(
      `Date:${rowData.date} `,
      30,
      80,
      0,
      20
    );
    doc.text(
      `Sales Man:${rowData.salesMenId.name} `,
      30,
      100,
      0,
      20
    );
    doc.text(`Area: ${rowData.areaId.areaName}`, 30, 120, 0, 20);
    doc.text(
      `Booker: ${rowData.bookerDetails.userName}`,
      30,
      140,
      0,
      20
    );


    doc.setFontSize(6);
    doc.autoTable(content);
    console.log(doc.lastAutoTable.finalY, "yyyyyyyyyyy")
    doc.setFontSize(14);
    doc.lastAutoTable.finalY > 730 && doc.addPage()
    let finalY = doc.lastAutoTable.finalY > 730 ? 5 : doc.lastAutoTable.finalY;
    // doc.rect(100, finalY + 20, 450, 100);
    doc.text(
      `SubTotal: `,
      400,
      finalY + 40
    );
    doc.text(
      `Return Amount: `,
      400,
      finalY + 55
    );
    doc.text(
      `Discount: `,
      400,
      finalY + 70
    );
    doc.text(
      `Credit: `,
      400,
      finalY + 85
    );
    doc.text(
      `Credit Receive: `,
      400,
      finalY + 100
    );
    // doc.line(400, finalY + 70, 550, finalY + 70);
    doc.text(
      `Grand Total: `,
      400,
      finalY + 115
    );
    doc.text(
      `${rowData.subTotal}`,
      500,
      finalY + 40
    );
    doc.text(
      `${rowData.returnAmount}`,
      500,
      finalY + 55
    );
    doc.text(
      `${rowData.bookingDiscount}`,
      500,
      finalY + 70
    );
    doc.text(
      `${rowData.credit}`,
      500,
      finalY + 85
    );
    doc.text(
      `${rowData.creditReceive}`,
      500,
      finalY + 100
    );
    // doc.line(400, finalY + 70, 550, finalY + 70);
    doc.text(
      `${rowData.finalPaidAmount
      }`,
      500,
      finalY + 115
    );

    doc.setProperties(
      `Payment Report ${moment(new Date()).format("YYYY-MM-DD")}`
    );
    var blob = doc.output("blob");
    window.open(URL.createObjectURL(blob));

    //       dispatch(successGenerateIssuancePDF(getData));
    //     })
    //     .catch((error) => {
    //       dispatch(errorGenerateIssuancePDF());
    //     });
  }
};

export const requestGetPaymentByDate = () => {
  return {
    type: "REQUEST_GET_PAYMENT_BY_DATE",
  };
};
export const successGetPaymentByDate = (data) => {
  return {
    type: "SUCCESS_GET_PAYMENT_BY_DATE",
    payload: data,
  };
};
export const errorGetPaymentByDate = () => {
  return {
    type: "ERROR_GET_PAYMENT_BY_DATE",
  };
};
export const getPaymentByDate = (data) => {
  return (dispatch) => {
    dispatch(requestGetPaymentByDate());
    API.post(`/issuance/payment_history_with_dates`, data)
      .then((res) => {
        let data1 = res.data;
        dispatch(successGetPaymentByDate(data1));
      })
      .catch((error) => {
        dispatch(errorGetPaymentByDate());
      });
  };
};

export const requestGetIssuanceByDate = () => {
  return {
    type: "REQUEST_GET_ISSUANCE_BY_DATE",
  };
};
export const successGetIssuanceByDate = (data) => {
  return {
    type: "SUCCESS_GET_ISSUANCE_BY_DATE",
    payload: data,
  };
};
export const errorGetIssuanceByDate = () => {
  return {
    type: "ERROR_GET_ISSUANCE_BY_DATE",
  };
};
export const getIssuanceByDate = (data) => {
  return (dispatch) => {
    dispatch(requestGetIssuanceByDate());
    API.post(`/issuance/issuance_with_date_filter`, data)
      .then((res) => {
        let data1 = res.data;
        dispatch(successGetIssuanceByDate(data1));
      })
      .catch((error) => {
        dispatch(errorGetIssuanceByDate());
      });
  };
};

export const UpdateRequestPayment = () => {
  return {
    type: "UPDATE_REQUEST_PAYMENT",
  };
};
export const UpdateSuccessPayment = (data) => {
  return {
    type: "UPDATE_SUCCESS_PAYMENT",
    payload: data,
  };
};

export const UpdateErrorPayment = () => {
  return {
    type: "UPDATE_ERROR_PAYMENT",
  };
};

export const updatePayment = (data, oldData, updateTableQuery) => {
  console.log(data, oldData, "aaaaaaaaaaaaaaa")
  return (dispatch) => {
    dispatch(UpdateRequestPayment());
    API.put(
      `/issuance/${oldData._id}`,
      {
        credit: Number(data.credit),
        creditReceive: Number(data.creditReceive),
        // subTotal:Number(oldData.subTotal)-Number(oldData.returnAmount)-Number(oldData.bookingDiscount),
        finalPaidAmount: Number(oldData.subTotal) - Number(oldData.returnAmount) - Number(oldData.bookingDiscount) - Number(data.credit) + Number(data.creditReceive)
      },
      {}
    )
      .then((res) => {
        dispatch(UpdateSuccessPayment([data]));
        updateTableQuery()
      })
      .catch((error) => dispatch(UpdateErrorPayment()));
  };
};
export const requestGenerateIssuancePDFWithDate = () => {
  return {
    type: "REQUEST_GENERATE_ISSUANCE_PDF_WITHDATE",
  };
};
export const successGenerateIssuancePDFWithDate = (data) => {
  return {
    type: "SUCCESS_GENERATE_ISSUANCE_PDF_WITHDATE",
    payload: data,
  };
};
export const errorGenerateIssuancePDFWithDate = () => {
  return {
    type: "ERROR_GENERATE_ISSUANCE_PDF_WITHDATE",
  };
};
export const generateIssuancePDFWithDate = (data) => {
  return (dispatch) => {
    // let name = data.name;
    dispatch(requestGenerateIssuancePDFWithDate());
    API.post(`/issuance/issuance_with_date_filter`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "Dataaaaaa");
        const totalSumSaleValue = getData.data.reduce((sum, item) => {
          const allDataSum = item.data.reduce((subSum, entry) => subSum + entry.totalSaleValue, 0);
          return sum + allDataSum;
      }, 0);
      const sumGrandTotal = totalSumSaleValue - sumBy(getData.data,'bookingDiscount')
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 4;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(11);
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        // doc.setFontSize(6);
        // doc.rect(15, 170, 550, 50);
        doc.setFontSize(20);
        doc.setFont("bold");
        doc.text(companyName, 220, 30, 0, 20);
        doc.setFontSize(16);
        doc.setFont("TimesNewRoman", "bold");
        doc.text(`Sum Grand Total = ${sumGrandTotal}`, 40, 60, 0, 20);
        let a = groupBy(getData.data, 'salesMenId.name')
        const headers = [
          ["Id", "Date", "Area", "Booker", "Salesmen", "Total", "Discount", "Grand Total","Status"],
        ];
        const keys = Object.keys(a)
        const values = Object.values(a)

        let y = 90
        keys.map((j, k) => {
          doc.text(j, 40, y)
          const data = values[k].map((x, i) => (
            console.log(x,'testtttttttt'),
            [
            x.number,
            x.date,
            x.areaId.areaName,
            x.bookerDetails.userName,
            x.salesMenId.name,
            sumBy(x.data,'totalSaleValue'),
            x.bookingDiscount,
            sumBy(x.data,'totalSaleValue')-x.bookingDiscount,
            x.status
          ]));
          data.push(["", "", "","", "Total", sumBy(data, '5'), sumBy(data, '6'), sumBy(data, '7'),""])
          let content = {
            margin: {
              left: 5,
              right: 10,
              // size:2
            },
            startY: y + 8,
            styles: { fontSize: 9 },
            head: headers,
            body: data,
            theme: "grid",
          };

          console.log(content, 'check1')
          doc.autoTable(content);
          y = doc.lastAutoTable.finalY + 10
        })
  
      

        // doc.rect(15, 160, 550, 100);
        // doc.autoTable(content);

        doc.setFontSize(10);

        doc.setProperties(
          `Order Issuance Report ${moment(new Date()).format("YYYY-MM-DD")}`
        );
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));


        dispatch(successGenerateIssuancePDFWithDate(getData));
      })
      .catch((error) => {
        console.log(error, 'errrrrrrrrrrrrrrrr')
        dispatch(errorGenerateIssuancePDFWithDate());
      });
  };
};