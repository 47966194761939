import API from "../../../../../global/api";
import jsPDF from "jspdf";
import { sumBy, isEmpty, isUndefined } from "lodash";
import moment from "moment";
import { handleNoDataVisible } from "../../../../../global/globalFunctions";

export const requestAddReporting = () => {
  return {
    type: "REQUEST_ADD_REPORTING",
  };
};
export const successAddReporting = (data) => {
  return {
    type: "SUCCESS_ADD_REPORTING",
    payload: data,
  };
};
export const errorAddReporting = () => {
  return {
    type: "ERROR_ADD_REPORTING",
  };
};
export const addReporting = (id, data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddReporting());
    API.post(`/issuance/issuance_with_bookerId/${id}`, data)
      .then((res) => {
        dispatch(successAddReporting(res.data));
        handleVisible(res.data.message);
      })
      .catch((error) => {
        dispatch(errorAddReporting());
        handleDangerVisible(error.response.data.message);
      });
  };
};

export const requestGetReporting = () => {
  return {
    type: "REQUEST_GET_REPORTING",
  };
};
export const successGetReporting = (data) => {
  return {
    type: "SUCCESS_GET_REPORTING",
    payload: data,
  };
};
export const errorGetReporting = () => {
  return {
    type: "ERROR_GET_REPORTING",
  };
};
export const getReporting = (data) => {
  return (dispatch) => {
    dispatch(requestGetReporting());
    API.get(`/reporting?limit=1000&page=0&search`)
      .then((res) => {
        let data1 = res.data;
        dispatch(successGetReporting(data1));
      })
      .catch((error) => {
        dispatch(errorGetReporting());
      });
  };
};

export const requestGenerateSaleReportPDF = () => {
  return {
    type: "REQUEST_GENERATE_SALE_REPORT_PDF",
  };
};
export const successGenerateSaleReportPDF = (data) => {
  return {
    type: "SUCCESS_GENERATE_SALE_REPORT_PDF",
    payload: data,
  };
};
export const errorGenerateSaleReportPDF = () => {
  return {
    type: "ERROR_GENERATE_SALE_REPORT_PDF",
  };
};

export const generateSaleReportPDF = (
  id,
  data,
  startDate,
  endDate,
  handleDangerVisible
) => {
  return (dispatch) => {
    // let name = data.name;
    dispatch(requestGenerateSaleReportPDF());
    API.post(`/issuance/issuance_with_bookerId/${id}`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getDataaaaa");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
        const marginLeft = 4;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(11);
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyPhone = `${process.env.REACT_APP_COMPANY_PHONE}`;
        const fromDate = "From Date " + " " + startDate;
        const toDate = "To Date " + " " + endDate;
        doc.setFontSize(6);
        const headers = [["Sr#", "Date", "Sale", "Area"]];
        const data = getData.data.map((x, i) => [
          i + 1,
          x.date,
          sumBy(x.data, "totalSaleValue") - x.bookingDiscount,

          x.areaId.areaName,
        ]);
        let content = {
          margin: {
            left: 40,
            right: 40,
            // size:2
          },
          startY: 150,
          styles: { fontSize: 9 },
          head: headers,
          body: data,
          theme: "grid",
        };
        doc.setFontSize(20);
        doc.setFont("bold");
        doc.text(companyName, 340, 50, 0, 20);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 280, 70, 0, 20);
        doc.text(`Ph: ${companyPhone}`, 320, 90, 0, 20);
        doc.setFont("bold");
        doc.text("Sales Detail Booker Wise", 340, 120, 0, 20);
        doc.line(330, 125, 480, 125);
        doc.setFontSize(12);
        doc.text(`${getData.data[0].bookerDetails.userName}`, 40, 145, 0, 20);
        doc.text(fromDate, 300, 145, 0, 20);
        doc.text(toDate, 450, 145, 0, 20);
        doc.autoTable(content);

        doc.setFontSize(12);
        let finalY = doc.lastAutoTable.finalY;
        doc.text(`Total Sale ${sumBy(data, "2")}`, 410, finalY + 30);
        // doc.save(
        //   `Order Booking Report ${moment(new Date()).format("YYYY-MM-DD")}`
        // );
        doc.setProperties(
          `Sales Detail Report ${moment(new Date()).format("YYYY-MM-DD")}`
        );
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));

        dispatch(successGenerateSaleReportPDF(getData));
      })
      .catch((error) => {
        dispatch(errorGenerateSaleReportPDF());
        handleDangerVisible();
      });
  };
};

export const requestGenerateNetSaleReportPDF = () => {
  return {
    type: "REQUEST_GENERATE_NET_SALE_REPORT_PDF",
  };
};
export const successGenerateNetSaleReportPDF = (data) => {
  return {
    type: "SUCCESS_GENERATE_NET_SALE_REPORT_PDF",
    payload: data,
  };
};
export const errorGenerateNetSaleReportPDF = () => {
  return {
    type: "ERROR_GENERATE_NET_SALE_REPORT_PDF",
  };
};
export const generateNetSaleReportPDF = (
  id,
  data,
  startDate,
  endDate,
  handleDangerVisible
) => {
  return (dispatch) => {
    // let name = data.name;
    dispatch(requestGenerateSaleReportPDF());
    API.post(`/issuance/issuance_with_bookerId/${id}`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getDataaaaa");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 4;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(11);
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyPhone = `${process.env.REACT_APP_COMPANY_PHONE}`;
        const fromDate = "From Date " + " " + startDate;
        const toDate = "To Date " + " " + endDate;

        doc.setFontSize(6);
        const headers = [["Sr#", "Date", "Sale", "Return", "Net Sale", "Area"]];
        const data = getData.data.map((x, i) => [
          i + 1,
          x.date,
          sumBy(x.data, "totalSaleValue") - x.bookingDiscount,
          sumBy(x.returnProducts, "totalSalePrice"),
          sumBy(x.data, "totalSaleValue") -
          x.bookingDiscount -
          (isEmpty(x.returnProducts) ||
            isUndefined(sumBy(x.returnProducts, "totalSalePrice"))
            ? 0
            : sumBy(x.returnProducts, "totalSalePrice")),
          x.areaId.areaName,
        ]);
        let content = {
          margin: {
            left: 25,
            right: 45,
            // size:2
          },
          startY: 140,
          styles: { fontSize: 9 },
          head: headers,
          body: data,
          theme: "grid",
        };
        doc.setFontSize(20);
        doc.setFont("bold");
        doc.text(companyName, 220, 50, 0, 20);
        // doc.setFontSize(13);
        // doc.setFont("TimesNewRoman", "Normal");
        // doc.text(companyAddress, 280, 70, 0, 20);
        // doc.text(`Ph: ${companyPhone}`, 320, 90, 0, 20);
        // doc.setFont("bold");
        // doc.text("Sales Detail Booker Wise", 340, 120, 0, 20);
        // doc.line(330, 125, 480, 125);
        doc.setFontSize(12);
        doc.rect(30, 100, 520, 30);
        doc.text(`${getData.data[0].bookerDetails.userName}`, 40, 120, 0, 20);
        doc.text(fromDate, 270, 120, 0, 20);
        doc.text(toDate, 410, 120, 0, 20);
        doc.autoTable(content);

        doc.setFontSize(12);
        let finalY = doc.lastAutoTable.finalY;
        doc.rect(30, finalY + 10, 520, 35);
        doc.text(`Total`, 50, finalY + 30, 0, 20);
        doc.text(`${sumBy(data, "2")}`, 175, finalY + 30);
        doc.text(`${sumBy(data, "3")}`, 255, finalY + 30);
        doc.text(`${sumBy(data, "4")}`, 325, finalY + 30);
        // doc.save(
        //   `Order Booking Report ${moment(new Date()).format("YYYY-MM-DD")}`
        // );
        doc.setProperties(
          `Sales Detail Report ${moment(new Date()).format("YYYY-MM-DD")}`
        );
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));

        dispatch(successGenerateSaleReportPDF(getData));
      })
      .catch((error) => {
        dispatch(errorGenerateSaleReportPDF());
        handleDangerVisible();
      });
  };
};

export const requestGenerateStockReportPDF = () => {
  return {
    type: "REQUEST_GENERATE_STOCK_REPORT_PDF",
  };
};
export const successGenerateStockReportPDF = (data) => {
  return {
    type: "SUCCESS_GENERATE_STOCK_REPORT_PDF",
    payload: data,
  };
};
export const errorGenerateStockReportPDF = () => {
  return {
    type: "ERROR_GENERATE_STOCK_REPORT_PDF",
  };
};

export const generateStockReportPDF = (data, handleDangerVisible, vendorName, Categoryname, restaurantName) => {
  console.log(data, "aaaaa");
  let CategoryId = data.CategoryId == "" ? "" : `&category=${data.CategoryId}`;
  let vendorId = data.vendorId == "" ? "" : `&vendorId=${data.vendorId}`;

  return (dispatch) => {
    // let name = data.name;
    dispatch(requestGenerateStockReportPDF());
    API.get(
      `/products/?wareHouseId=${data.warehouseID}${CategoryId}${vendorId}`
    )
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getDataaaaa");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 4;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(11);
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyPhone = `${process.env.REACT_APP_COMPANY_PHONE}`;
        // const fromDate = "From Date " + " " + startDate;
        // const toDate = "To Date " + " " + endDate;

        doc.setFontSize(6);
        const headers = [
          ["Sr#", "Code", "Product", "Uom", "Qty", "Price", "Value"],
        ];
        const data = getData.data.map((x, i) => [
          i + 1,
          x.code,
          x.name,
          x.uomLabel,
          x.productBalance,
          x.purchasePrice,
          Number((x.productBalance * x.purchasePrice).toFixed(2)),
        ]);
        data.push(["", "", "", "Total", sumBy(data, '4'), sumBy(data, '5').toFixed(2), sumBy(data, '6').toFixed(2)])
        let content = {
          margin: {
            left: 15,
            right: 15,
            // size:2
          },
          startY: 185,
          styles: { fontSize: 11 },
          head: headers,
          body: data,
          theme: "grid",
        };
        doc.setFontSize(20);
        doc.setFont("bold");
        doc.text(companyName, 230, 50, 0, 20);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 170, 70, 0, 20);
        doc.text(`Ph: ${companyPhone}`, 210, 90, 0, 20);
        doc.setFontSize(20);
        doc.setFont("bold");
        doc.text("Stock Detail", 240, 120, 0, 20);
        // doc.line(330, 125, 480, 125);
        doc.setFont("TimesNewRoman", "Normal");
        doc.setFontSize(14);
        doc.text(`Warehouse: ${restaurantName}`, 40, 130, 0, 20);
        doc.text(`Category: ${Categoryname}`, 40, 150, 0, 20);
        doc.text(`Vendor: ${vendorName}`, 40, 170, 0, 20);
        doc.setFontSize(12);
        doc.autoTable(content);

        // doc.setFontSize(12);
        // let finalY = doc.lastAutoTable.finalY;
        // doc.rect(30, finalY + 10, 720, 35);
        // doc.text(`Total`, 50, finalY + 30, 0, 20);
        // doc.text(`${sumBy(data, "2")}`, 250, finalY + 30);
        // doc.text(`${sumBy(data, "3")}`, 355, finalY + 30);
        // doc.text(`${sumBy(data, "4")}`, 460, finalY + 30);
        // doc.save(
        //   `Order Booking Report ${moment(new Date()).format("YYYY-MM-DD")}`
        // );
        doc.setProperties(
          `Stock Detail Report ${moment(new Date()).format("YYYY-MM-DD")}`
        );
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));

        dispatch(successGenerateStockReportPDF(getData));
      })
      .catch((error) => {
        dispatch(errorGenerateStockReportPDF());
        handleDangerVisible();
      });
  };
};

export const generateStockReportPDFWithRetailPrice = (data, handleDangerVisible, vendorName, Categoryname, restaurantName) => {
  console.log(data, "aaaaa");
  let CategoryId = data.CategoryId == "" ? "" : `&category=${data.CategoryId}`;
  let vendorId = data.vendorId == "" ? "" : `&vendorId=${data.vendorId}`;

  return (dispatch) => {
    // let name = data.name;
    dispatch(requestGenerateStockReportPDF());
    API.get(
      `/products/?wareHouseId=${data.warehouseID}${CategoryId}${vendorId}`
    )
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getDataaaaa");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 4;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(11);
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyPhone = `${process.env.REACT_APP_COMPANY_PHONE}`;
        // const fromDate = "From Date " + " " + startDate;
        // const toDate = "To Date " + " " + endDate;

        doc.setFontSize(6);
        const headers = [
          ["Sr#", "Code", "Product", "Uom", "Qty", "Price", "Value"],
        ];
        const data = getData.data.map((x, i) => [
          i + 1,
          x.code,
          x.name,
          x.uomLabel,
          x.productBalance,
          x.salePrice,
          parseInt((x.productBalance * x.salePrice).toFixed(2)),
        ]);
        data.push(["","","Total","", sumBy(data, '4'), sumBy(data, '5'), sumBy(data, '6')])
        let content = {
          margin: {
            left: 15,
            right: 15,
            // size:2
          },
          startY: 185,
          styles: { fontSize: 11 },
          head: headers,
          body: data,
          theme: "grid",
        };
        doc.setFontSize(20);
        doc.setFont("bold");
        doc.text(companyName, 230, 50, 0, 20);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 170, 70, 0, 20);
        doc.text(`Ph: ${companyPhone}`, 210, 90, 0, 20);
        doc.setFontSize(20);
        doc.setFont("bold");
        doc.text("Stock Detail", 240, 120, 0, 20);
        // doc.line(330, 125, 480, 125);
        doc.setFont("TimesNewRoman", "Normal");
        doc.setFontSize(14);
        doc.text(`Warehouse: ${restaurantName}`, 40, 130, 0, 20);
        doc.text(`Category: ${Categoryname}`, 40, 150, 0, 20);
        doc.text(`Vendor: ${vendorName}`, 40, 170, 0, 20);
        doc.setFontSize(12);
        doc.autoTable(content);

        // doc.setFontSize(12);
        // let finalY = doc.lastAutoTable.finalY;
        // doc.rect(30, finalY + 10, 720, 35);
        // doc.text(`Total`, 50, finalY + 30, 0, 20);
        // doc.text(`${sumBy(data, "2")}`, 250, finalY + 30);
        // doc.text(`${sumBy(data, "3")}`, 355, finalY + 30);
        // doc.text(`${sumBy(data, "4")}`, 460, finalY + 30);
        // doc.save(
        //   `Order Booking Report ${moment(new Date()).format("YYYY-MM-DD")}`
        // );
        doc.setProperties(
          `Stock Detail Report ${moment(new Date()).format("YYYY-MM-DD")}`
        );
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));

        dispatch(successGenerateStockReportPDF(getData));
      })
      .catch((error) => {
        dispatch(errorGenerateStockReportPDF());
        handleDangerVisible();
      });
  };
};
// payment
export const requestGeneratePaymentReportPDF = () => {
  return {
    type: "REQUEST_GENERATE_PAYMENT_REPORT_PDF",
  };
};
export const successGeneratePaymentReportPDF = (data) => {
  return {
    type: "SUCCESS_GENERATE_PAYMENT_REPORT_PDF",
    payload: data,
  };
};
export const errorGeneratePaymentReportPDF = () => {
  return {
    type: "ERROR_GENERATE_PAYMENT_REPORT_PDF",
  };
};

export const generatePaymentReportPDF = (data, startDate, endDate, salesMenName) => {
  return (dispatch) => {
    // let name = data.name;
    dispatch(requestGeneratePaymentReportPDF());
    API.post(`/issuance/payment_history_with_salesmen`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getDataaaaa");
        if (isEmpty(getData.data)) {
          handleNoDataVisible()
        }
        else {
          const unit = "pt";
          const size = "A4"; // Use A1, A2, A3 or A4
          const orientation = "landscape"; // portrait or landscape
          const marginLeft = 4;
          const doc = new jsPDF(orientation, unit, size);
          doc.setFontSize(11);
          const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
          const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
          const companyPhone = `${process.env.REACT_APP_COMPANY_PHONE}`;
          //   const fromDate = "From Date " + " " + startDate;
          //   const toDate = "To Date " + " " + endDate;
          doc.setFontSize(6);
          doc.setFontSize(20);
          doc.setFont("bold");
          doc.text(companyName, 40, 40, 0, 20);
          doc.setFontSize(12);
          doc.setFont("TimesNewRoman", "Normal");
          // doc.text(companyAddress, 245, 60, 0, 20);
          // doc.text(`Ph: ${companyPhone}`, 270, 80, 0, 20);
          doc.setFontSize(15);
          doc.setFont("bold");
          doc.text("Payment Report", 300, 40, 0, 20);
          // doc.text(`From ${startDate} to ${endDate}`,20,140)
          doc.setFontSize(14);
          doc.setFont("TimesNewRoman", "Normal");
          // doc.rect(15, 120, 550, 60);
          // salesMenName != "" && doc.text(`Salesmen Name:${salesMenName}`, 20, 120, 0, 20);
          doc.text(`From ${startDate} to ${endDate}`, 270, 60)
          const headers = [["Sr#", "B-Date","P-Date", "Booker Name", "Area", "Total", "Discount", "Return", "Credit", 'Credit Rec', 'Grand Total', 'Amount Rec', 'Short', 'Remarks']];
          if (salesMenName == "") {
            getData = getData.data.reduce(function (r, a) {
              r[a.salesMenId.name] = r[a.salesMenId.name] || [];
              r[a.salesMenId.name].push(a);
              return r;
            }, Object.create(null));
            const keys = Object.keys(getData)
            const values = Object.values(getData)
            let y = 80
            keys.map((j, k) => {
              doc.text(j, 40, y)
              const data = values[k].map((x, i) => [
                i + 1,
                x.date,
                x.paymentDate,
                x.bookerDetails.userName,
                x.areaId.areaName,
                x.subTotal,
                x.bookingDiscount,
                x.returnAmount,
                x.credit,
                x.creditReceive,
                x.finalPaidAmount,
                x.receiveAmount,
                x.shortAmount,
                x.remarks
                // x.subTotal-
              ]);
              data.push(["", "", "","", "Total", sumBy(data, '5'), sumBy(data, '6'), sumBy(data, '7'), sumBy(data, '8'), sumBy(data, '9'), sumBy(data, '10'), sumBy(data, '11'), sumBy(data, '12'), ""])
              let content = {
                margin: {
                  left: 5,
                  right: 10,
                  // size:2
                },
                startY: y + 8,
                styles: { fontSize: 9 },
                head: headers,
                body: data,
                theme: "grid",
              };
  
              console.log(content, 'check1')
              doc.autoTable(content);
              y = doc.lastAutoTable.finalY + 10
            })
            console.log(res.data.data,"uuuuuuuuuuuuuuuuuuuuuuuuuu")
            const headers1 = [["Total", "Discount","Return", "Credit", "Credit Receive", "Grand Total", "Received Amount", "Short"]];
            let content1 = {
              margin: {
                left: 5,
                right: 10,
                // size:2
              },
              startY: y + 10,
              styles: { fontSize: 9 },
              head: headers1,
              body: [[sumBy(res.data.data,'subTotal'),sumBy(res.data.data,'bookingDiscount'),sumBy(res.data.data,'returnAmount'),sumBy(res.data.data,'credit'),sumBy(res.data.data,'creditReceive'),sumBy(res.data.data,'finalPaidAmount'),sumBy(res.data.data,'receiveAmount'),sumBy(res.data.data,'shortAmount')]],
              theme: "grid",
            };
            doc.autoTable(content1);

          }
          else {
            doc.text(`Salesmen Name:${salesMenName}`, 20, 140, 0, 20);
            const data = getData.data.map((x, i) => [
              i + 1,
              x.date,
              x.paymentDate,
              x.bookerDetails.userName,
              x.areaId.areaName,
              x.subTotal,
              x.bookingDiscount,
              x.returnAmount,
              x.credit,
              x.creditReceive,
              x.finalPaidAmount,
              x.receiveAmount,
              x.shortAmount,
              x.remarks
              // x.subTotal-
            ]);
            data.push(["", "", "","", "Total", sumBy(data, '5'), sumBy(data, '6'), sumBy(data, '7'), sumBy(data, '8'), sumBy(data, '9'), sumBy(data, '10'), sumBy(data, '11'), sumBy(data, '12'), ""])
            let content = {
              margin: {
                left: 5,
                right: 10,
                // size:2
              },
              startY: 160,
              styles: { fontSize: 9 },
              head: headers,
              body: data,
              theme: "grid",
            };
            doc.autoTable(content);
          }
          doc.setProperties(
            `Payment Report ${moment(new Date()).format("YYYY-MM-DD")}`
          );
          var blob = doc.output("blob");
          window.open(URL.createObjectURL(blob));
        }
        dispatch(successGeneratePaymentReportPDF(getData));
      })
      .catch((error) => {
        console.log(error, "ttttttttttttt")
        dispatch(errorGeneratePaymentReportPDF());
      });
  };
};
// daily 
export const requestAreaWiseShops = () => {
  return {
    type: "REQUEST_AREA_WISE_SHOPS",
  };
};
export const successAreaWiseShops = (data) => {
  return {
    type: "SUCCESS_AREA_WISE_SHOPS",
    payload: data,
  };
};
export const errorAreaWiseShops = () => {
  return {
    type: "ERROR_AREA_WISE_SHOPS",
  };
};

export const areaWiseShops = (id) => {
  return (dispatch) => {
    // let name = data.name;
    dispatch(requestAreaWiseShops());
    API.get(`/shop/area_wise_shops/${id}`)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getDataaaaa");
        isEmpty(getData.data) && handleNoDataVisible()
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 4;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(11);
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyPhone = `${process.env.REACT_APP_COMPANY_PHONE}`;
        //   const fromDate = "From Date " + " " + startDate;
        //   const toDate = "To Date " + " " + endDate;
        doc.setFontSize(6);
        const headers = [["Sr#", "Shop Name", "location", "Owner Name", "Phone Number", "Cnic"]];

        const data = getData.data.map((x, i) => [
          i + 1,
          x.shopName,
          x.location,
          x.ownerName,
          x.ownerNumber,
          x.ownerCnic,
        ]);
        let content = {
          margin: {
            left: 15,
            right: 15,
            // size:2
          },
          startY: 140,
          styles: { fontSize: 11 },
          head: headers,
          body: data,
          theme: "grid",
        };
        doc.setFontSize(20);
        doc.setFont("bold");
        doc.text(companyName, 220, 40, 0, 20);
        doc.setFontSize(12);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 175, 60, 0, 20);
        doc.text(`Ph: ${companyPhone}`, 200, 80, 0, 20);
        doc.setFontSize(15);
        doc.setFont("bold");
        doc.text("Area Wise Shops List", 220, 100, 0, 20);
        // doc.text(`From ${startDate} to ${endDate}`,20,140)
        doc.setFontSize(14);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(`Area Name:${getData.data[0].areaDetails.areaName}`, 20, 120, 0, 20);
        doc.autoTable(content);
        doc.setProperties(
          `Purchase Report ${moment(new Date()).format("YYYY-MM-DD")}`
        );
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));

        dispatch(successAreaWiseShops(getData));
      })
      .catch((error) => {
        console.log(error, "ttttttttttttt")
        dispatch(errorAreaWiseShops());
      });
  };
};
export const requestGenerateSaleReportProductWise = () => {
  return {
    type: "REQUEST_GENERATE_SALE_REPORT_PDF",
  };
};
export const successGenerateSaleReportProductWise = (data) => {
  return {
    type: "SUCCESS_GENERATE_SALE_REPORT_PDF",
    payload: data,
  };
};
export const errorGenerateSaleReportProductWise = () => {
  return {
    type: "ERROR_GENERATE_SALE_REPORT_PDF",
  };
};

export const generateSaleReportProductWise = (
  id,
  data,
  startDate,
  endDate,
  bookerName,
  handleDangerVisible
) => {
  return (dispatch) => {
    // let name = data.name;
    dispatch(requestGenerateSaleReportProductWise());
    API.post(`/issuance/booker_sale_report/${id}`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getDataaaaa");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
        const marginLeft = 4;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(11);
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyPhone = `${process.env.REACT_APP_COMPANY_PHONE}`;
        const fromDate = "From Date " + " " + startDate;
        const toDate = "To Date " + " " + endDate;
        doc.setFontSize(6);
        const headers = [["Sr#", "Product", "Qty", "Return Qty", "Final Qty", "Rate", "Value"]];
        const data = getData.data.map((x, i) => [
          i + 1,
          x.productLabel,
          x.quantity,
          x.returnProductQuantity,
          x.quantity - x.returnProductQuantity,
          x.productSellingPrice,
          x.totalSaleValue,
        ]);
        data.push(["", "Total", sumBy(data, '2'), sumBy(data, '3'), sumBy(data, '4'), "", sumBy(data, '6')])
        let content = {
          margin: {
            left: 40,
            right: 40,
            // size:2
          },
          startY: 150,
          styles: { fontSize: 9 },
          head: headers,
          body: data,
          theme: "grid",
        };
        doc.setFontSize(20);
        doc.setFont("bold");
        doc.text(companyName, 340, 50, 0, 20);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 280, 70, 0, 20);
        doc.text(`Ph: ${companyPhone}`, 320, 90, 0, 20);
        doc.setFont("bold");
        doc.text("Sales Detail Booker Wise", 340, 120, 0, 20);
        doc.line(330, 125, 480, 125);
        doc.setFontSize(12);
        doc.text(`Booker Name: ${bookerName}`, 40, 145, 0, 20);
        doc.text(fromDate, 300, 145, 0, 20);
        doc.text(toDate, 450, 145, 0, 20);
        doc.autoTable(content);

        doc.setFontSize(12);
        let finalY = doc.lastAutoTable.finalY;
        // doc.text(`Total Sale ${sumBy(data, "4")}`, 510, finalY + 30);
        // doc.save(
        //   `Order Booking Report ${moment(new Date()).format("YYYY-MM-DD")}`
        // );
        doc.setProperties(
          `Sales Detail Report ${moment(new Date()).format("YYYY-MM-DD")}`
        );
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));

        dispatch(successGenerateSaleReportProductWise(getData));
      })
      .catch((error) => {
        dispatch(errorGenerateSaleReportProductWise());
        handleDangerVisible();
      });
  };
};

export const requestGenerateSaleReportBookerCompanyWise = () => {
  return {
    type: "REQUEST_GENERATE_SALE_REPORT_PDF",
  };
};
export const successGenerateSaleReportBookerCompanyWise = (data) => {
  return {
    type: "SUCCESS_GENERATE_SALE_REPORT_PDF",
    payload: data,
  };
};
export const errorGenerateSaleReportBookerCompanyWise = () => {
  return {
    type: "ERROR_GENERATE_SALE_REPORT_PDF",
  };
};

export const generateSaleReportBookerCompanyWise = (
  data,
  startDate,
  endDate,
  handleDangerVisible
) => {
  return (dispatch) => {
    // let name = data.name;
    dispatch(requestGenerateSaleReportBookerCompanyWise());
    API.post(`/reports/sale_report_company_booker_wise`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getDataaaaa");
        let totalSubTotal = 0;
        Object.values(getData).forEach((vendorArray) => {
            vendorArray.forEach((entry) => {
                totalSubTotal += entry.subTotal - entry.bookingDiscount - entry.returnAmount || 0; // Add subTotal if it exists
            });
        });
        console.log(totalSubTotal, "kkkkkkkkkkkkkk")
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 4;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(11);
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyPhone = `${process.env.REACT_APP_COMPANY_PHONE}`;
        const fromDate = "From Date " + " " + startDate;
        const toDate = "To Date " + " " + endDate;
        doc.setFontSize(20);
        doc.setFont("bold");
        doc.text(companyName, 210, 50, 0, 20);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        // doc.text(companyAddress, 150, 70, 0, 20);
        // doc.text(`Ph: ${companyPhone}`, 190, 90, 0, 20);
        // doc.setFont("bold");
        // doc.text("Sales Report Company Booker Wise", 210, 110, 0, 20);
        // doc.line(330, 125, 480, 125);
        doc.setFontSize(12);
        // doc.text(`Booker Name: ${bookerName}`, 40, 145, 0, 20);
        doc.text(fromDate, 170, 70, 0, 20);
        doc.text(toDate, 300, 70, 0, 20);
        doc.text(`Sum Of NetSale = ${totalSubTotal}`, 40, 100, 0, 20);
        doc.setFontSize(14);
        const headers = [["Booker", "Sale", "Discount", "Return", "Net Sale"]];
        const keys = Object.keys(getData)
        const values = Object.values(getData)
        let y = 130
        keys.map((j, k) => {
          doc.text(j, 15, y)
          const data = values[k].map((x, i) => [
            x.bookerDetails.userName,
            x.subTotal,
            x.bookingDiscount,
            x.returnAmount,
            x.subTotal - x.bookingDiscount - x.returnAmount,
          ]);
          data.push(["Total", sumBy(data, '1'), sumBy(data, '2'), sumBy(data, '3'), sumBy(data, '4')])
          let content = {
            margin: {
              left: 5,
              right: 10,
              // size:2
            },
            startY: y + 10,
            styles: { fontSize: 11 },
            head: headers,
            body: data,
            theme: "grid",
          };
          doc.autoTable(content);
          y = doc.lastAutoTable.finalY + 15
        })
        // console.log(values, 'check1')
        // let content1 = {
        //   margin: {
        //     left: 5,
        //     right: 10,
        //     // size:2
        //   },
        //   startY: y + 15,
        //   styles: { fontSize: 11 },
        //   head: headers,
        //   body: [["Grand Total",sumBy(values,'subTotal'),sumBy(data,'2'),sumBy(data,'3'), sumBy(data, '4')]],
        //   theme: "grid",
        // };
        //      doc.autoTable(content1)
   
        // doc.autoTable(content);

        doc.setFontSize(12);
        // let finalY = doc.lastAutoTable.finalY;
        // doc.text(`Total Sale ${sumBy(data, "4")}`, 510, finalY + 30);
        // doc.save(
        //   `Order Booking Report ${moment(new Date()).format("YYYY-MM-DD")}`
        // );
        doc.setProperties(
          `Sales Detail Report ${moment(new Date()).format("YYYY-MM-DD")}`
        );
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));

        dispatch(successGenerateSaleReportBookerCompanyWise(getData));
      })
      .catch((error) => {
        dispatch(errorGenerateSaleReportBookerCompanyWise());
        handleDangerVisible();
      });
  };
};
